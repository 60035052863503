import React from 'react';

const WithComponent = ({
  withComponent,
  component: Component,
  componentProps,
  children,
}) =>
  withComponent ? (
    <Component {...componentProps}>{children}</Component>
  ) : (
    children
  );
export default WithComponent;
