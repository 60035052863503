import {
  convertMinutesToSeconds,
  isSameUrlInHistory,
  saveUrlToHistory,
  removeUrlFromHistory,
} from '../../../helpers/timer';

export default class Timer {
  constructor() {
    this.id = [];
    this.timerMinutes = 2;
  }

  startInit = (baseUrl, newsSource) => {
    if (isSameUrlInHistory()) {
      removeUrlFromHistory();
      return;
    }

    this.timerId = setTimeout(() => {
      saveUrlToHistory();
      window.open(baseUrl, '_self');

      if (window.gtag) {
        window.gtag('event', 'Open news by timer', {
          event_category: newsSource,
        });
      }
    }, convertMinutesToSeconds(this.timerMinutes));

    if (this.timerId) this.addId(this.timerId);
  };

  addId = id => this.id.push(id);

  onStop = () => {
    this.id.map(timerId => clearTimeout(timerId));
    this.id = [];
  };
}
