import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LogoLoader from './LogoLoader';
import './style.scss';

const modifyLoader = ({ inline, isLoading }) =>
  classNames({
    inline,
    loader: true,
    'loader--active': isLoading,
  });

const Loader = props => {
  const { color, logo, isLoading } = props;

  return isLoading ? (
    <div className={modifyLoader(props)} style={{ color }}>
      {logo ? (
        <LogoLoader />
      ) : (
        <div>
          <span />
          <span />
          <span />
        </div>
      )}
    </div>
  ) : null;
};

Loader.defaultProps = {
  color: '#673ab7',
};

Loader.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.bool,
};

export default Loader;
