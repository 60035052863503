import React from 'react';
import {bool} from 'prop-types';

const LogoBasic = ({isMobileHeader, rootClassName}) => {
  return isMobileHeader ? (
    <>
      <span>my.</span>top
    </>
  ) : (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="2 0 80 72"
        width="47"
        height="40"
      >
        <path d="M0 72h71.9V0H0"></path>
        <path d="M37.357 45.407c-1.76 0-3.158 1.043-3.912 3.128.036.287.036.54.036.826v10.532h-3.947v-10.46c0-1.94-1.15-4.025-3.733-4.025-1.723 0-3.16 1.007-3.877 3.056v11.43h-3.948V41.92h3.948v1.69c1.22-1.366 2.98-1.976 4.63-1.976 2.513 0 4.415 1.042 5.6 2.767 1.687-2.227 4.163-2.766 5.922-2.766 4.415 0 6.927 3.09 6.927 7.727v10.532h-3.948v-10.46c0-1.94-1.113-4.025-3.697-4.025zm10.576-3.773h4.236l4.88 12.4 4.917-12.4h4.236L55.937 66.218h-4.163l3.23-7.548-7.07-17.036z"></path>
        <path d="M77.42 53.634c1.722 0 3.086 1.4 3.086 3.09 0 1.726-1.364 3.128-3.087 3.128s-3.123-1.402-3.123-3.127c0-1.69 1.4-3.09 3.122-3.09"></path>
      </svg>
      <div className={`${rootClassName}-title`}>top</div>
    </>
  );
};

LogoBasic.propTypes = {
  isMobileHeader: bool,
};

export default LogoBasic;
