import query from 'querystringify';
import { getOtherRubricLength } from '../helpers/system';

// Predefined params
const predefinedParams = {
  hasCommercial: false,
};

const abcBase = {
  ...predefinedParams,
  shouldSendViewedNewsFromIframe: true,
  isObserveNews: true,
  isMultipleSending: true,
  enableOrderParamForBlocks: ['last', 'main', 'rubric'],
  enableLangParam: true,
  newsItemsCount: { initQty: { last: 8, rubric: 12 } },
  rubricPeriod: { period: 'fourteen_days' },
  sortingOrder: 'ctr',
  isRubricInfinite: true,
  template: 'basic',

  // Label: last approved changes (abc=62):
  switchBaseNews: {
    isBaseNewsFromRubric: false,
    baseNewsFromRubricIndex: 0,
    originBaseNews: null,
    originRubricLength: getOtherRubricLength({
      hasCommercial: predefinedParams.hasCommercial
    }),
    currentBaseNews: {},
  },
  isHideClickedNews: true,
};

const abcAttributes = {
  // Basic template
  // 66: abcBase,

  // Archived tests (weren't used):
  // 55: { ...abcBase, googleFont: 'Roboto' },
  // 56: { ...abcBase, googleFont: 'Open Sans' },
  // 57: { ...abcBase /* Font: Euclid */ },
  // 58: { ...abcBase, isMobileHeader: true }, // Same header on desktop (zen)
};

for (let i = 67; i < (67 + 20); i++){
  abcAttributes[i] = abcBase;
}

export const getAbcNumber = () => query.parse(window.location.search).a_b_c;

export const getAbcAttributes = () => {
  const abcNumber = getAbcNumber();
  const isAbc = abcNumber && abcNumber in abcAttributes;

  return isAbc ? abcAttributes[abcNumber] : abcBase;
};
