import React from 'react';
import Mobile from './components/Mobile';
import Desktop from './components/Desktop';

import './styles.scss';
import { iTemplateProps } from '../../../types';

const BasicTemplate = props => {
  const { isMobile } = props;

  return isMobile ? (
    <Mobile {...props} />
  ) : (
    <Desktop {...props} />
  );
};

BasicTemplate.propTypes = iTemplateProps;

export default BasicTemplate;
