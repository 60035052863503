import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const rootClassName = 'nav';

// Navigation items

const navItems = [
  { title: 'Политика', id: '2ccb6651-07c8-4efc-a85d-48a19ee7eb0d' },
  { title: 'Культура', id: '38942212-aa89-11e7-b327-0242ac120003' },
  { title: 'Общество', id: '0954c07c-a48c-44f6-90c9-1e2399aefa77' },
  { title: 'Украина', id: 'f6bded74-325d-11e7-95af-000000000001' },
  { title: 'Здоровье', id: 'f7b1a07e-aa99-11e7-b742-0242ac120003' },
  { title: 'Технологии', id: '4156d48c-ab3b-11e7-86ed-0242ac120003' },
  { title: 'Экономика', id: '86aac001-835e-4bf4-8618-1dfe45f3bd1b' },
  { title: 'Происшествия', id: 'e9401616-90d6-4c8d-8543-a76879ad5774' },
];

// Modify class names

const modifyNav = isMobile =>
  classNames({ [`${rootClassName}--mobile`]: isMobile });

const modifyNavItem = (item, activeNavItem) =>
  classNames({
    [`${rootClassName}__item--active`]:
      activeNavItem && activeNavItem.id === item.id,
  });

const Nav = props => {
  const { isMobile, onNavItemClick, activeNavItem } = props;

  return (
    <div className={`${rootClassName} ${modifyNav(isMobile)}`}>
      {navItems.map(item => (
        <a
          className={`${rootClassName}__item ${modifyNavItem(
            item,
            activeNavItem,
          )}`}
          onClick={e => onNavItemClick(e, item)}
          href={`#${item.id}`}
          key={item.id}
        >
          {item.title}
        </a>
      ))}
    </div>
  );
};

Nav.propTypes = {
  isMobile: PropTypes.bool,
  onNavItemClick: PropTypes.func.isRequired,
  activeNavItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default Nav;
