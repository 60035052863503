import React, { Component } from 'react';

// Helpers
import { getDomain } from './helpers/system';
import { getAbcNumber, getAbcAttributes } from './services/abcParams';

// Styles
import './style.scss';

// Components
import DetailNews from './components/common/DetailNews';
import Timer from './components/common/Timer';
import templates from './components/templates/templates';
import { setLocalStorage } from './services/localStorage';

class App extends Component {
  constructor(props) {
    super(props);

    this.hotNewsRef = React.createRef();
    this.lastNewsBlockRef = React.createRef();

    this.state = {
      url: null,
      timer: '',
      isFirstLoad: true,
      isShowOtherMaterial: false,
      shownItems: {
        last: [],
        main: [],
        rubric: [],
      },
      abcNumber: null,
      currentDate: new Date(),
      ...getAbcAttributes(),
    };
  }

  timer = new Timer();

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.url !== prevState.url)
      return {
        ...prevState,
        url: nextProps.url,
      };

    return null;
  }

  componentDidMount() {
    this.initLoad();
    window.scrollTo(0, 0);
    this.setState({
      abcNumber: getAbcNumber(),
    });
  }

  componentWillUnmount() {
    if (this.timer) {
      this.timer.onStop();
    }
  }

  initLoad = () => {
    const { data, actions, isTimerDisabled } = this.props;
    const { source } = data[0].content.base;

    const redirectUrl = actions.getUrl(data[0].content.base);

    this.initFirstItems();

    // label TIMER
    if (!isTimerDisabled) {
      this.timer.startInit(redirectUrl, source);
    }
  };

  initFirstItems = () => {
    const { data, loadedItems, otherRubricLength } = this.props;
    const { isFirstLoad } = this.state;
    const { initQty } = loadedItems;
    const { content } = data[0];
    const isCutForOtherRubricNews = key =>
      isFirstLoad &&
      key === 'rubric' &&
      content[key].length >= otherRubricLength;

    let initedItems = {};
    Object.keys(initQty).forEach(key => {
      initedItems[key] = content[key].slice(0, initQty[key]);
    });

    this.setState({
      shownItems: initedItems,
      isFirstLoad: false,
      isShowOtherMaterial: isCutForOtherRubricNews('rubric'),
    });
  };

  loadFromState = newsType => {
    const { loadedItems } = this.props;
    const { shownItems } = this.state;

    const settings = loadedItems.addQty;

    let newShownItems = Object.assign({}, shownItems);
    Object.keys(shownItems).forEach(key => {
      if (newsType && newsType !== key) return;

      const neededItemsLength = shownItems[key].length + settings[key];
      const slicedItems = loadedItems[key].slice(
        shownItems[key].length,
        neededItemsLength,
      );

      newShownItems[key] = newShownItems[key].concat(slicedItems);
    });

    this.setState({ shownItems: newShownItems }, () => {
      const { actions } = this.props;
      let shouldLoad = false;

      if (newsType) {
        shouldLoad = this.checkAbilityToLoad(newsType);
      } else {
        shouldLoad = Object.keys(shownItems).some(key =>
          this.checkAbilityToLoad(key),
        );
      }

      if (shouldLoad) {
        actions.load();
      }
    });
  };

  checkAbilityToLoad = newsType => {
    const { isRequesting, loadedItems } = this.props;
    return (
      this.isAllItemsFromStateShown(newsType) &&
      !isRequesting &&
      !loadedItems.isEmptyLoad[newsType]
    );
  };

  isAllItemsFromStateShown = type => {
    const { loadedItems } = this.props;
    const { shownItems } = this.state;

    return loadedItems[type].length === shownItems[type].length;
  };

  renderDetailNews = (
    newsArray,
    newsComponent = DetailNews,
    componentProps = {},
  ) => {
    const { data, actions, isObserveNews } = this.props;
    const { getUrl, observeNewsHandler } = actions;
    const { base } = data[0].content;

    return newsArray.map((item, i) => {
      const NewsComponent = newsComponent;
      const newsProps = {
        ...item,
        title: item.title,
        img: item.img,
        url: getUrl(item),
        baseUrl: base.url,
        callback: (data) => this.mainClick(data, item),
        position: i + 1,
        ...(isObserveNews ? { onObserve: observeNewsHandler } : {}),
        ...componentProps,
      };

      return (
        <div className="news-item-wrapper" key={item.news_id}>
          <NewsComponent {...newsProps} />
        </div>
      );
    });
  };

  handleOnAdsClick = () => {
    this.props.actions.newsClickHandler({
      position: 1,
      block: 'ad',
    });
  };

  // abc=62
  getBaseNewsFromRubric = () => {
    const { data, actions, switchBaseNews, setBaseNewsFromRubric } = this.props;
    const {
      baseNewsFromRubricIndex: currentIndex,
      isBaseNewsFromRubric: isBaseChanged,
      originBaseNews,
    } = switchBaseNews;

    this.setState(state => {
      const newState = Object.assign({}, state);
      const { shownItems } = newState;

      // Copies
      const rubric = [...shownItems.rubric];
      const base = { ...rubric[currentIndex] };

      // Detecting origin base news
      const originBase = isBaseChanged
        ? originBaseNews
        : { ...data[0].content.base };

      // Set origin base as a first news in rubric list
      const newRubric = [...(isBaseChanged ? [] : [originBase]), ...rubric];

      // Hide/show news depending on current index
      if (currentIndex > 0) {
        rubric[currentIndex - 1].isHidden = false;
      }
      rubric[currentIndex].isHidden = true;

      // Switching base news
      setBaseNewsFromRubric(base);

      // Restarting timer
      this.timer.onStop();
      this.timer.startInit(
        actions?.getUrl(base),
        base?.source,
      );

      return {
        ...newState,
        shownItems: { ...newState.shownItems, rubric: newRubric },
      };
    });
  };

  // abc=62
  hideNewsAfterClick = ({ news_id, block }) => {
    this.setState(state => {
      const { shownItems } = Object.assign({}, state);

      if(shownItems.rubric.filter(el => !el.isHidden).length < 10) {
        this.loadFromState(block);
      }

      const currentIndex = shownItems[block].findIndex(
        el => el.news_id === news_id,
      );

      shownItems[block][currentIndex].isHidden = true;

      return { ...state, shownItems };
    });
  };

  getOriginalPosition = (data) => {
    const {loadedItems, switchBaseNews } = this.props;
    const {isBaseNewsFromRubric} = switchBaseNews;
    const {news_id, block, position} = data;

    const getPosition = (newsId, block) => loadedItems[block]
      && loadedItems[block].findIndex(el => newsId === el.news_id) + 1;
    
    return block === 'base'
      ? isBaseNewsFromRubric ? getPosition(news_id,'rubric' ) : position
      : getPosition(news_id, block)
  };

  mainClick = (data, ...restProps) => {
    const { switchBaseNews, data: dataProp, isHideClickedNews } = this.props;
    const currentBaseNewsId =
      switchBaseNews?.currentBaseNews?.news_id ||
      dataProp[0]?.content?.base?.news_id;
    const isBaseNewsClick = data.news_id === currentBaseNewsId;
    const isOriginBaseClick = data.news_id === switchBaseNews?.originBaseNews?.news_id;

    const dataCorrect = { 
      ...data,
      ...(switchBaseNews && {
        position: this.getOriginalPosition(data),
        block: data.block === 'base' && switchBaseNews?.isBaseNewsFromRubric
          ? 'rubric' : data.block,
      })
    };

    // saving data for selenium
    setLocalStorage('seleniumData', { lastClickedNews: dataCorrect });

    this.timer.onStop();

    const onNewTabOpening = () => {
      // Replacing base news from rubric
      if (switchBaseNews && isBaseNewsClick) {
        this.getBaseNewsFromRubric();
      }

      // Hiding regular news after click
      if (isHideClickedNews && !isBaseNewsClick && !isOriginBaseClick) {
        this.hideNewsAfterClick(dataCorrect);
      }

      // Enabled only for a new tab opening
      window.removeEventListener('blur', onNewTabOpening);
    };
    window.addEventListener('blur', onNewTabOpening);

    if (this.props.actions.newsClickHandler) {
      this.props.actions.newsClickHandler(dataCorrect, ...restProps);
    }
  };

  render() {
    const { data, template } = this.props;
    const { base } = data[0].content;
    const domain = getDomain(base.url);
    const Template = templates[template];

    return (
      <Template
        {...this.props}
        {...this.state}
        domain={domain}
        base={base}
        mainClick={this.mainClick}
        renderDetailNews={this.renderDetailNews}
        hotNewsRef={this.hotNewsRef}
        lastNewsBlockRef={this.lastNewsBlockRef}
        handleOnAdsClick={this.handleOnAdsClick}
        loadFromState={this.loadFromState}
      />
    );
  }
}

export default App;
