import React, { useState, useEffect } from 'react';
import './style.scss';

const items = [
  {
    titlePosition: 4,
  },
  {
    titlePosition: 3,
  },
  {
    titlePosition: 4,
  },
  {
    titlePosition: 8,
  },
];

const LogoLoader = () => {
  const [active, setActive] = useState(null);

  const changeActive = () =>
    setActive(prevActive =>
      !prevActive || prevActive === 4 ? 1 : prevActive + 1,
    );

  useEffect(() => {
    setTimeout(changeActive);
    const intervalId = setInterval(changeActive, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="btn-logo btn-logo-slider">
      {items.map((item, index) => (
        <div
          key={index}
          className={`btn-logo-item logo-style-${index + 1} ${
            active === index + 1 ? 'in' : ''
          } `}
        >
          <div className="btn-logo-item-container">
            {[...Array(8).keys()].map(piece =>
              piece + 1 === item.titlePosition ? (
                <div key={piece} className="btn-logo-item-piece">
                  <span>my</span>
                </div>
              ) : (
                <div key={piece} className="btn-logo-item-piece" />
              ),
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LogoLoader;
