import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validateJSON from '../../../helpers/validateJSON';

class Iframe extends Component {
  iframe = React.createRef();

  state = {
    isIframeResponds: false,
    timeoutId: '',
  };

  componentDidMount() {
    window.addEventListener('message', this.iframeMessageHandler, false);
    this.initFrame();

    // init if iframe is not responded
    const timeoutId = setTimeout(this.enableInitIfNoIframe, 2000);
    this.setState({ timeoutId });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.iframeMessageHandler);
  }

  enableInitIfNoIframe = () =>
    !this.state.isIframeResponds &&
    this.iframeMessageHandler({
      origin: process.env.REACT_APP_IFRAME_DOMAIN,
      data: JSON.stringify({
        user: null,
        iframeMessage: this.iframeMessage,
      }),
    });

  disableInitIfNoIframe = () => {
    this.setState({ isIframeResponds: true }, () => {
      clearTimeout(this.state.timeoutId);
    });
  };

  initFrame = () => {
    const iframe = this.iframe.current;
    iframe.addEventListener('load', this.broadcastIframeData);
  };

  iframeMessageHandler = event => {
    if (event.origin !== process.env.REACT_APP_IFRAME_DOMAIN) return;

    this.disableInitIfNoIframe();

    if (validateJSON(event.data) && typeof event.data === 'string') {
      const data = JSON.parse(event.data);
      if ('user' in data) {
        const regexpUuid = /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}\-){3})([0-9a-fA-F]{12})$/i;
        const isDefinedUser = data.user && data.user.match(regexpUuid) !== null;

        const user = isDefinedUser ? data.user : null;
        this.props.getUser(user, this.iframeMessage);
      }
      if ('news_ids' in data && Array.isArray(data.news_ids)) {
        const newsIds = data.news_ids || [];
        this.props.getNewsIds(newsIds);
      }
    }
  };

  broadcastIframeData = () => {
    this.iframeMessage('get', 'news_ids');
    this.iframeMessage('get', 'user');
  };

  iframeMessage = (type, key, value) => {
    const { iframe } = this;
    const receiverWindow = iframe.current.contentWindow;

    const data = JSON.stringify({
      type,
      key,
      value,
      messageSource: 'mypagestats',
    });

    receiverWindow.postMessage(data, '*');
  };

  render() {
    return (
      <iframe
        title="iframe"
        ref={this.iframe}
        src={process.env.REACT_APP_IFRAME}
        width="0"
        height="0"
        style={{ borderWidth: 0, display: 'block' }}
      />
    );
  }
}

Iframe.propTypes = {
  getUser: PropTypes.func.isRequired,
  getNewsIds: PropTypes.func.isRequired,
};

export default Iframe;
