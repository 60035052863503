import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { shape, func, arrayOf, string, exact, number, bool } from 'prop-types';

// Components
import NewsItem from './NewsItem';
import {
  iCorrection,
  iNewsItem,
  iRef,
  iTemplateProps,
} from '../../../../../../types';

const NewsList = ({
  shownItemsBlock,
  componentProps,
  setCorrection,
  lastItemsRefs,
  generalProps,
  correction,
  nextBlock,
  block,
  news,
}) => {
  const { data, actions, mainClick, likes, setLike, isObserveNews } = generalProps;
  const { getUrl, observeNewsHandler } = actions;
  const base = data[0].content;
  const newsClassNames = {
    0: 'left', 6: 'right',
  };
  const newsBlockLength = 7;
  const classNamesMark = direction =>
    classNames({
      [`news-item-wrapper--${direction}`]: direction !== '',
    });

  const setShownItems = useCallback(() => {
    if (shownItemsBlock && shownItemsBlock.length && nextBlock) {
      setCorrection(prevState => ({
        ...prevState,
        [nextBlock.block]: (shownItemsBlock.length + prevState[block]) % newsBlockLength,
      }));
    }
  }, [block, nextBlock, setCorrection, shownItemsBlock]);

  useEffect(setShownItems, [shownItemsBlock]);

  return news.map((item, i) => {
    const position = (i + correction[block]) % newsBlockLength;
    const direction = newsClassNames[position] || '';

    return (
      <NewsItem
        {...item}
        {...componentProps}
        key={item.news_id}
        block={block}
        title={item.title}
        img={item.img}
        url={getUrl(item)}
        baseUrl={base.url}
        callback={mainClick}
        position={i + 1}
        isBigNews={direction === 'left' || direction === 'right'}
        className={`news-item-wrapper ${classNamesMark(direction)}`}
        onObserve={isObserveNews ? observeNewsHandler : null}
        loaderRef={lastItemsRefs[block]}
        isLastItem={i === news.length - 1}
        likes={likes}
        setLike={setLike}
      />
    );
  });
};

NewsList.propTypes = {
  shownItemsBlock: arrayOf(exact(iNewsItem)),
  componentProps: exact({
    imageWidth: number,
    imageHeight: number,
    isDesktopTemplate: bool,
  }),
  setCorrection: func,
  lastItemsRefs: iRef,
  generalProps: shape(iTemplateProps),
  correction: exact(iCorrection),
  nextBlock: shape({}),
  block: string,
  news: arrayOf(shape(iNewsItem)),
};

export default NewsList;
