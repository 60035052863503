import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { decodeEntities } from '../../../services/decodeData';
import ThumborImage from '../ThumborImage';
import useIntersection from '../../../hooks/useIntersection';
import './style.scss';

const TileNews = props => {
  const {
    view_signature,
    view_uuid,
    onObserve,
    callback,
    position,
    news_id,
    source,
    block,
    mix,
    img,
    url,
  } = props;
  const { observerEntry, observerRef } = useIntersection();
  const newsTrackingData = {
    news_id,
    position,
    block,
    view_signature,
    view_uuid,
  };
  const title = decodeEntities(props.title);

  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);

  return (
    <a
      ref={observerRef}
      href={url}
      target="_blank"
      rel="noopener"
      referrerPolicy="origin"
      className={`tile-news-wrapper ${mix ? 'mix' : ''}`}
      onMouseDown={() => callback(newsTrackingData, { source })}
    >
      <div className="tile-news-content-wrapper">
        <div className="tile-news-img-wrapper">
          <ThumborImage
            src={img}
            alt={title}
            className="tile-news-img"
            width={250}
            height={130}
          />
        </div>

        <span className="tile-news-title">{title}</span>
      </div>
    </a>
  );
};

TileNews.propTypes = {
  callback: PropTypes.func,
  baseUrl: PropTypes.string,
  img: PropTypes.string,
  mix: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

export default memo(TileNews);
