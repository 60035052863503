import React, { useState, useRef } from 'react';
import {shape, arrayOf, string, number, exact, bool} from 'prop-types';

// Components
import NewsList from './components/NewsList';
import WithScroll from '../../../../common/HOC/WithScroll';
import InfiniteScroll from '../InfiniteScroll';
import { iNewsItem, iTemplateProps } from '../../../../../types';

export const ZenNews = ({ allNews, componentProps = {}, generalProps }) => {
  const {
    loadedItems,
    shownItems,
    isRequesting,
    loadFromState,
    isRubricInfinite,
  } = generalProps;
  const [correction, setCorrection] = useState({
    base: 0,
    rubric: 1,
    last: 1,
    main: 1,
  });
  const lastItemsRefs = {
    base: useRef(),
    rubric: useRef(),
    last: useRef(),
    main: useRef(),
  };

  return Object.values(allNews).map(({ block, news }, i, arr) => (
    <WithScroll
      key={i}
      isWithScroll={block !== 'base'}
      scrollComponent={InfiniteScroll}
      hasMore={
        loadedItems[block] &&
        loadedItems[block].length > shownItems[block].length
      }
      isLoading={isRequesting && !loadedItems.isEmptyLoad[block]}
      onLoadMore={() => loadFromState(block)}
      buttonProps={{ filled: true }}
      useButton={!isRubricInfinite}
      loaderRef={lastItemsRefs[block]}
    >
      <NewsList
        block={block}
        news={news}
        generalProps={generalProps}
        componentProps={componentProps}
        nextBlock={arr[i + 1]}
        correction={correction}
        setCorrection={setCorrection}
        shownItemsBlock={shownItems[block]}
        lastItemsRefs={lastItemsRefs}
      />
    </WithScroll>
  ));
};

ZenNews.propTypes = {
  allNews: exact({
    0: shape({
      block: string,
      news: arrayOf(shape(iNewsItem))
    }),
    1: shape({
      block: string,
      news: arrayOf(shape(iNewsItem))
    }),
    2: shape({
      block: string,
      news: arrayOf(shape(iNewsItem))
    }),
    3: shape({
      block: string,
      news: arrayOf(shape(iNewsItem))
    }),
  }),
  componentProps: exact({
    imageWidth: number,
    imageHeight: number,
    isDesktopTemplate: bool,
  }),
  generalProps: shape(iTemplateProps)
}
