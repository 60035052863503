import { useEffect, useState, useCallback } from 'react';

// Utils
import { getLocalStorage, setLocalStorage } from '../../../services/localStorage';
import { LOCAL_STORAGE_LIKES } from '../../../constants';

const useLikes = () => {
  const [likes, setLikes] = useState(getLocalStorage(LOCAL_STORAGE_LIKES, []));

  const setLike = (newsId, reaction) =>
    setLikes(likes => {
      let newLikes = Array.isArray(likes) ? [...likes] : [];
      const currentIndex = newLikes.findIndex(({ newsId: id }) => newsId === id);

      if (currentIndex >= 0) {
        newLikes[currentIndex] = { newsId, reaction };
      } else {
        newLikes.push({ newsId, reaction });
      }

      return newLikes;
    });

  const saveToStorage = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_LIKES, likes);
  }, [likes]);

  useEffect(saveToStorage, [likes]);

  return [likes, setLike];
};

export default useLikes;
