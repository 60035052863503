import { DEVICE_TYPE_DESKTOP, DEVICE_TYPE_MOBILE } from '../constants';

const isMobile = () => {
  try{
    document.createEvent("TouchEvent");
    return true;
  } catch(e){
    return false;
  }
};

export const getDeviceType = () => {
  const deviceData = {
    width: 1920,
    height: 1080,
    type: DEVICE_TYPE_DESKTOP,
  };

  try {
    const { width, height } = window.screen;
    deviceData.width = width;
    deviceData.height = height;
    deviceData.type = isMobile() ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_DESKTOP;
  } catch (error) {}

  return deviceData;
}