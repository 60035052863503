import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const TitleCategory = props => {
  return <h2 className="title-category">{props.title}</h2>;
};

TitleCategory.propTypes = {
  title: PropTypes.string,
};

export default TitleCategory;
