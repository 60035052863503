import query from 'querystringify';
import { checkFirstTimeLoading } from './localStorage';

export default function() {
  const initParams = {
    // view_news_id:1,
    // informer_id:1,
    // site_id:1,
  };

  /* eslint-disable */
  // label: Unknown regexps (legacy)
  const regex1 = /^[\],:{}\s]*$/;
  const regex2 = /\\["\\\/bfnrtu]/g;
  const regex3 = /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g;
  const regex4 = /(?:^|:|,)(?:\s*\[)+/g;
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  /* eslint-enable */
  const queryObject = query.parse(window.location.search);
  const decodedParams = queryObject.top_payload || null;

  const validParams = function(string) {
    let result = initParams;

    if (base64regex.test(string)) {
      const base64Parse = window.atob(decodeURIComponent(escape(string)));
      const cleanedBase64 = base64Parse
        .replace(regex2, '@')
        .replace(regex3, ']')
        .replace(regex4, '');

      if (regex1.test(cleanedBase64)) {
        try {
          result = JSON.parse(base64Parse);
        } catch (e) {}
      }
    }

    result.is_counted = checkFirstTimeLoading(result);

    return result;
  };

  const params = {
    ...validParams(decodedParams),
    ...(queryObject.a_b_c && { a_b_c: +queryObject.a_b_c }),
  };

  // window.history.replaceState({}, document.title,'/');

  return params;
}
