// Keys

export const SESSION_KEY = 'myua_session';
export const VIEWED_NEWS_KEY = 'myua_viewed_news';
export const OBSERVED_NEWS_KEY = 'myua_observed_news';
export const LAST_SENDED_NEWS_INDEX = 'myua_last_sended_news_index';
export const START_ROTATING_NEWS_DATE_KEY = 'myua_start_rotating_news_date';

// Number constants

// Quantity of news to rotate with every new session
export const ROTATED_NEWS_COUNT = 10;

// Quantity of blocks to push to the localStorage
export const ROTATED_NEWS_BLOCKS_COUNT = 8;

// Days, how long we will keep viewed news ids in localstorage
export const NEWS_EXPIRES_IN_DAYS = 7;


// label: LOCAL STORAGE
export const LOCAL_STORAGE_LIKES = 'likes';
export const LOCAL_STORAGE_PARAMS = 'urlParams';
export const LOCAL_STORAGE_NEWS = 'news';

export const DEVICE_TYPE_DESKTOP = 'desktop';
export const DEVICE_TYPE_MOBILE = 'mobile';