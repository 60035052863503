const storageUrlKey = 'historyUrl';

export const isSameUrlInHistory = () =>
  localStorage.getItem(storageUrlKey) === window.location.href;

export const saveUrlToHistory = () =>
  localStorage.setItem(storageUrlKey, window.location.href);

export const removeUrlFromHistory = () =>
  localStorage.removeItem(storageUrlKey);

export const convertMinutesToSeconds = minutes => {
  return minutes * 1000 * 60;
};