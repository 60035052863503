import React, { useEffect, useCallback } from 'react';
import {shape, func, bool, element} from 'prop-types';

// Components
import Loader from '../../../../common/Loader';
import Button from '../../../../common/Button';

const InfiniteScroll = props => {
  const {
    loaderComponent,
    buttonProps,
    onLoadMore,
    useButton,
    isLoading,
    children,
    hasMore,
    loaderRef,
  } = props;

  const intersectionHandler = useCallback(
    ([target]) => {
      const isLoadMore = (target.isIntersecting && hasMore) ||
      (!target.isIntersecting && target.boundingClientRect.y < 0);

      if (isLoadMore) {
        onLoadMore();
      }
    },
    [hasMore, onLoadMore],
  );

  useEffect(() => {
    if (!loaderRef.current || useButton) return;

    const loader = loaderRef.current;
    const options = {
      threshold: 0.25,
      rootMargin: `${window.innerHeight * 2}px`,
    };

    const observer = new IntersectionObserver(intersectionHandler, options);

    if (loader) {
      observer.observe(loader);
    }

    if (!hasMore) {
      observer.unobserve(loader);
    }

    return () => observer.unobserve(loader);
  }, [loaderRef, intersectionHandler, hasMore, useButton]);

  return (
    <>
      {children}
      {isLoading && (loaderComponent || <Loader inline logo />)}
      {useButton && hasMore && (
        <Button
          title="Показать еще"
          style={{ marginTop: 15 }}
          callback={onLoadMore}
          {...buttonProps}
        />
      )}
    </>
  );
};

InfiniteScroll.propTypes = {
  loaderComponent: func,
  buttonProps: shape({}),
  onLoadMore: func,
  useButton: bool,
  isLoading: bool,
  children: element,
  hasMore: bool,
  loaderRef: shape({}),
}

export default InfiniteScroll;
