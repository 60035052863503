import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const rootClassName = 'sticky-container';

const modifyContainer = isShown =>
  classNames({
    [`${rootClassName}--shown`]: isShown,
  });

const modifyArrowContainer = isVisible =>
  classNames({
    [`${rootClassName}__arrow-container--out`]: !isVisible,
  });

const StickyContainer = props => {
  const { children } = props;
  const [isVisible, setVisible] = useState(false);
  const [isShown, setShown] = useState(false);
  const [isDisabledSticky, setDisabledSticky] = useState(false);

  const stickyContainerRef = useRef(null);

  useEffect(() => {
    const element = stickyContainerRef.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setVisible(true);
      } else if (entry.isIntersecting) {
        setVisible(false);
      }
    });
    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect(element);
  }, [stickyContainerRef, isShown, isDisabledSticky]);

  const arrowClickHandler = useCallback(
    () => setShown(prevShown => !prevShown),
    [],
  );

  const disableStickyHandler = useCallback(
    isEnabled => setDisabledSticky(prevState => !isEnabled || !prevState),
    [],
  );

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { disableStickyHandler }),
  );

  return (
    <>
      <div
        className={`${rootClassName} ${modifyContainer(
          isShown || isDisabledSticky,
        )} ${rootClassName}--${children.props.template || ''}`}
      >
        <div className={`${rootClassName}__children`}>{childrenWithProps}</div>

        <div
          className={`${rootClassName}__arrow-container ${modifyArrowContainer(
            isVisible,
          )}`}
        >
          <div
            className={`${rootClassName}__arrow `}
            onClick={arrowClickHandler}
          >
            <svg x="0px" y="0px" viewBox="0 0 512.011 512.011">
              <path
                d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0
			s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667
			C514.096,145.416,514.096,131.933,505.755,123.592z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div ref={stickyContainerRef}></div>
    </>
  );
};

StickyContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StickyContainer;
