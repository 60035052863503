import React from 'react';
import { string } from 'prop-types';

const HeadStyles = ({ googleFont }) => {
  return (
    <>
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="preconnect" href={process.env.REACT_APP_URL} />
      <link rel="preconnect" href={process.env.REACT_APP_IFRAME_DOMAIN} />
      <link rel="preconnect" href="http://counter.yadro.ru" />
      <link rel="preconnect" href="https://mc.webvisor.org" />

      {googleFont && (
        <link
          href={`https://fonts.googleapis.com/css?family=${googleFont.replace(' ', '+')}:400,400i,500,700&subset=cyrillic-ext&display=swap`}
          rel="stylesheet"
        />
      )}
    </>
  );
};

HeadStyles.propTypes = {
  googleFont: string,
};

export default HeadStyles;
