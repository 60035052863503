import React from 'react';
import MainNews from '../../../common/MainNews/AlternativeView';
import GoogleAdvertisement from '../../../common/GoogleAdvertisement';
import InfiniteScroll from '../../../common/InfiniteScroll';
import AlternativeDetailNews from '../../../common/DetailNews/AlternativeDetailNews';
import DetailNews from '../../../common/DetailNews';
import classNames from 'classnames';
import { iTemplateProps } from '../../../../types';
import NewsBlock from '../../../common/NewsBlock';
import TileNews from '../../../common/TileNews';

const Mobile = props => {
  const {
    base,
    domain,
    actions,
    mainClick,
    isObserveNews,
    abcNumber,
    activeNavItem,
    hasCommercial,
    loadedItems,
    shownItems,
    isRequesting,
    isRubricInfinite,
    isHideLastBlock,
    hiddenNewsBlocks,
    useImageForLastNews,
    handleOnAdsClick,
    loadFromState,
    lastNewsBlockRef,
  } = props;

  const imageWidth = window.innerWidth;
  const imageMainHeightProportion = 1.5;
  const imageMainHeight = Math.ceil(imageWidth / imageMainHeightProportion);
  const imageHeight = Math.ceil(imageWidth / 1.35);

  const mainNewsProps = {
    ...base,
    onObserve: isObserveNews && actions.observeNewsHandler,
    url: actions.getUrl(base),
    onClick: mainClick,
    id: base.news_id,
    source: domain,
    block: 'base',
    position: 1,
  };

  const modifyNewsContainer = ({ isHidden }) =>
    classNames({
      'news-container--hidden': isHidden,
    });

  return (
    <div className={'app app-mobile app-basic app--abc-' + abcNumber}>
      <div className="container">
        {/* label MAIN */}
        {!activeNavItem && (
          <>
            {base && (
              <MainNews
                {...mainNewsProps}
                isObserveNews={isObserveNews}
                imageWidth={imageWidth}
                imageHeight={imageMainHeight}
              />
            )}
            {hasCommercial && (
              <div className="ga-wrapper">
                <GoogleAdvertisement
                  clientId="ca-pub-1745069445907180"
                  slotId="9693921721"
                  handleOnAdsClick={handleOnAdsClick}
                />
              </div>
            )}
          </>
        )}

        {/* label RUBRIC */}

        <div className="news-container rubric">
          <div className="main-tiles-wrapper">
            <InfiniteScroll
              hasMore={loadedItems.rubric.length > shownItems.rubric.length}
              isLoading={isRequesting && !loadedItems.isEmptyLoad.rubric}
              onLoadMore={() => loadFromState('rubric')}
              buttonProps={{ filled: true }}
              useButton={!isRubricInfinite}
            >
              <NewsBlock
                newsArray={shownItems.rubric}
                newsComponent={AlternativeDetailNews}
                base={base}
                mainClick={mainClick}
                actions={actions}
                isObserveNews={isObserveNews}
                componentProps={{
                  block: 'rubric',
                  imageWidth,
                  imageHeight,
                }}
              />
            </InfiniteScroll>
          </div>
        </div>

        {/* label LAST */}
        {!isHideLastBlock && (
          <div
            className={`news-container last ${modifyNewsContainer({
              isHidden: hiddenNewsBlocks && hiddenNewsBlocks.includes('last'),
            })}`}
          >
            <div className="main-tiles-wrapper" ref={lastNewsBlockRef}>
              <InfiniteScroll
                hasMore={loadedItems.last.length > shownItems.last.length}
                isLoading={isRequesting && !loadedItems.isEmptyLoad.last}
                onLoadMore={() => loadFromState('last')}
                buttonProps={{ filled: true }}
                useButton
              >
                <NewsBlock
                  newsArray={shownItems.last}
                  newsComponent={useImageForLastNews ? AlternativeDetailNews : DetailNews}
                  base={base}
                  mainClick={mainClick}
                  actions={actions}
                  isObserveNews={isObserveNews}
                  componentProps={{
                    block: 'last',
                    withoutImage: true,
                    withMeta: true,
                    className: 'alternative',
                  }}
                />
              </InfiniteScroll>
            </div>
          </div>
        )}

        {/* label MAIN */}
        <div className="news-container main">
          <div className="main-tiles-wrapper">
            <InfiniteScroll
              hasMore={loadedItems.main.length > shownItems.main.length}
              isLoading={isRequesting && !loadedItems.isEmptyLoad.main}
              onLoadMore={() => loadFromState('main')}
            >
              <NewsBlock
                newsArray={shownItems.main}
                newsComponent={AlternativeDetailNews}
                base={base}
                mainClick={mainClick}
                actions={actions}
                isObserveNews={isObserveNews}
                componentProps={{
                  block: 'main',
                  imageWidth,
                  imageHeight,
                }}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

Mobile.propTypes = iTemplateProps;

export default Mobile;
