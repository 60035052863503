export const getDomain = url => {
  // eslint-disable-next-line no-useless-escape
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  return matches && matches[1];
};

export const getOtherRubricLength = ({hasCommercial = false}) =>
  (hasCommercial || window.innerWidth <= 1024) ? 4 : 6;

export const getNewsItemsCount = (customNewsItemsCount = {}) => {
  const windowWidth = window.innerWidth;
  if (windowWidth < 768) {
    // mobile view
    return {
      addQty: {
        last: 12,
        main: 12,
        rubric: 12,
        ...customNewsItemsCount.addQty,
      },
      initQty: {
        last: 10,
        main: 8,
        rubric: 8,
        ...customNewsItemsCount.initQty,
      },
    };
  }

  // desktop
  return {
    addQty: {
      last: 30,
      main: 30,
      rubric: 30,
    },
    initQty: { last: 30, main: 30, rubric: 30 },
  };
};
