import React from 'react';
import PropTypes from 'prop-types';
import jsSHA from 'jssha';

// Function for generating thumbor's url
// https://thumbor.readthedocs.io/en/latest/getting_started.html

const generateThumborImageUrl = (src, width, height, multiplier = 1) => {
  if (!src) {
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }

  const thumborPath = `${width * multiplier}x${
    height * multiplier
  }/smart/${encodeURIComponent(src)}`;
  const shaObject = new jsSHA('SHA-1', 'TEXT');
  shaObject.setHMACKey(process.env.REACT_APP_THUMBOR_SECRET_KEY, 'TEXT');
  shaObject.update(thumborPath);
  const hmac = shaObject.getHMAC('B64').replace(/\+/g, '-').replace(/\//g, '_');

  return `${process.env.REACT_APP_THUMBOR_URL}/${hmac}/${thumborPath}`;
};

const ThumborImage = props => {
  const { src, width, height, alt, className } = props;
  // Remove query params from image src because of thumbor get errors when parse query string
  const strippedSrc = src.includes('?') ? src.split('?')[0] : src;
  const imageUrl = generateThumborImageUrl(strippedSrc, width, height);
  const imageUrlLarge = generateThumborImageUrl(strippedSrc, width, height, 2);

  return (
    <img
      className={className}
      srcSet={`${imageUrl} 1x, ${imageUrlLarge} 2x`}
      src={imageUrl}
      alt={alt}
      loading="lazy"
      width={width}
      height={height}
    />
  );
};

ThumborImage.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

ThumborImage.defaultProps = {
  height: 300,
  width: 300,
};

export default ThumborImage;
