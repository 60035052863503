import React from 'react';

const LikeIcon = ({ isReversed, style, setIsLiked, isLiked }) => {
  const correctStyle = {
    ...style,
    ...(isLiked !== null ? { opacity: isLiked === !isReversed ? 1 : 0.4 } : {}),
  };

  const handleSetIsLiked = () => setIsLiked(!isReversed);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489.543 489.543"
      xmlSpace="preserve"
      onClick={handleSetIsLiked}
      style={{
        ...correctStyle,
        transform:
          `rotate(${isReversed ? 180 : 0}deg)` +
          `translatey(${isReversed ? '0px' : '-12px'})`,
        enableBackground: 'new 0 0 489.543 489.543',
      }}
    >
      <g>
        <g style={style}>
          <path
            d="M270.024,0c-22.6,0-15,48.3-15,48.3s-48.3,133.2-94.5,168.7c-9.9,10.4-16.1,21.9-20,31.3l0,0l0,0
c-0.9,2.3-1.7,4.5-2.4,6.5c-3.1,6.3-9.7,16-23.8,24.5l46.2,200.9c0,0,71.5,9.3,143.2,7.8c28.7,2.3,59.1,2.5,83.3-2.7
c82.2-17.5,61.6-74.8,61.6-74.8c44.3-33.3,19.1-74.9,19.1-74.9c39.4-41.1,0.7-75.6,0.7-75.6s21.3-33.2-6.2-58.3
c-34.3-31.4-127.4-10.5-127.4-10.5l0,0c-6.5,1.1-13.4,2.5-20.8,4.3c0,0-32.2,15,0-82.7C346.324,15.1,292.624,0,270.024,0z"
          />
          <path d="M127.324,465.7l-35-166.3c-2-9.5-11.6-17.3-21.3-17.3h-66.8l-0.1,200.8h109.1C123.024,483,129.324,475.2,127.324,465.7z" />
        </g>
      </g>
    </svg>
  );
};

export default LikeIcon;
