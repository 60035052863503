import React from 'react';
import { iTemplateProps } from '../../../../types';
import { ZenNews } from './ZenNews';

const Mobile = props => {
  const {
    base,
    domain,
    actions,
    mainClick,
    isObserveNews,
    abcNumber,
    shownItems,
  } = props;

  const imageWidth = 300;
  const imageHeight = 320;

  const mainNewsProps = {
    ...base,
    ...(isObserveNews ? { onObserve: actions.observeNewsHandler } : {}),
    url: base.url,
    onClick: mainClick,
    id: base.news_id,
    source: domain,
    block: 'base',
    position: 1,
    callback: mainClick,
    imageWidth,
    imageHeight,
  };

  return (
    <div className={'app app-desktop app-zen app--abc-' + abcNumber}>
      <div className="container">
        <div className="main-tiles-wrapper">
          <ZenNews
            allNews={{
              0: { block: 'base', news: [{ ...mainNewsProps }] },
              1: { block: 'rubric', news: shownItems.rubric },
              2: { block: 'last', news: shownItems.last },
              3: { block: 'main', news: shownItems.main },
            }}
            componentProps={{
              imageWidth,
              imageHeight,
              isDesktopTemplate: true,
            }}
            generalProps={props}
          />
        </div>
      </div>
    </div>
  );
};

Mobile.propTypes = iTemplateProps;

export default Mobile;
