import React from 'react';

import './styles.scss';
import { iTemplateProps } from '../../../types';

// Components
import Mobile from './components/Mobile';
import Desktop from './components/Desktop';
import useLikes from './useLikes';

const ZenTemplate = props => {
  const { isMobile } = props;
  const [likes, setLike] = useLikes();

  return isMobile ? (
    <Mobile {...props} likes={likes} setLike={setLike} />
  ) : (
    <Desktop {...props} likes={likes} setLike={setLike} />
  );
};

ZenTemplate.propTypes = iTemplateProps;

export default ZenTemplate;
