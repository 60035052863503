import React, { useEffect } from 'react';
import { decodeEntities } from '../../../services/decodeData';
import ThumborImage from '../ThumborImage';
import useIntersection from '../../../hooks/useIntersection';
import './alternativeView.scss';

const rootClassName = 'main-news-alternative';

const MainNews = props => {
  const {
    view_signature,
    view_uuid,
    onClick,
    url,
    img,
    source,
    imageHeight,
    imageWidth,
    id,
    position,
    block,
    onObserve,
  } = props;
  const title = decodeEntities(props.title);
  const newsTrackingData = {
    news_id: id,
    position,
    block,
    view_signature,
    view_uuid,
  };
  const { observerEntry, observerRef } = useIntersection();

  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);

  return (
    <a
      ref={observerRef}
      href={url}
      onMouseDown={() => onClick(newsTrackingData)}
      rel="noopener"
      referrerPolicy="origin"
      target="_blank"
      className={rootClassName}
    >
      <div className={`${rootClassName}__container`}>
        <ThumborImage
          src={img}
          alt={title}
          width={imageWidth}
          height={imageHeight}
          className={`${rootClassName}__image`}
        />
        <div className={`${rootClassName}__meta`}>
          <div className={`${rootClassName}__title`}>{title}</div>
          <div className={`${rootClassName}__buttons-block`}>
            <div className={`${rootClassName}__source`}>
              {source && (
                <>
                  <svg
                    width="438.536px"
                    height="438.536px"
                    viewBox="0 0 438.536 438.536"
                  >
                    <path
                      d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
                            C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
                            h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
                            C438.532,59.576,430.49,40.204,414.41,24.123z M365.449,228.402c0,7.994-3.717,13.606-11.136,16.844
                            c-2.471,0.951-4.859,1.427-7.139,1.427c-5.134,0-9.418-1.811-12.847-5.424l-41.11-41.112L140.764,352.599
                            c-3.621,3.614-7.9,5.425-12.85,5.425c-4.952,0-9.235-1.811-12.851-5.425l-29.121-29.126c-3.617-3.61-5.426-7.901-5.426-12.847
                            c0-4.944,1.809-9.229,5.426-12.843l152.462-152.464l-41.113-41.112c-5.902-5.52-7.233-12.178-3.999-19.985
                            c3.234-7.421,8.852-11.136,16.846-11.136h137.037c4.948,0,9.232,1.81,12.854,5.428c3.613,3.614,5.421,7.898,5.421,12.847V228.402z"
                    />
                  </svg>
                  {source}
                </>
              )}
            </div>
            <div className={`${rootClassName}__button`}>
              Читать далее
              <svg
                width="38.533px"
                height="38.533px"
                viewBox="0 0 438.533 438.533"
              >
                <path
                  d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0
                    c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267
                    c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407
                    s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062
                    C438.533,179.485,428.732,142.795,409.133,109.203z M365.446,237.539c0,4.948-1.808,9.236-5.421,12.847
                    c-3.621,3.614-7.898,5.431-12.847,5.431H203.855l53.958,53.958c3.429,3.425,5.14,7.703,5.14,12.847c0,5.14-1.711,9.418-5.14,12.847
                    l-25.981,25.98c-3.426,3.423-7.712,5.141-12.849,5.141c-5.136,0-9.419-1.718-12.847-5.141L102.783,258.093l-25.979-25.981
                    c-3.427-3.429-5.142-7.707-5.142-12.845c0-5.14,1.714-9.42,5.142-12.847l25.979-25.981L206.136,77.083
                    c3.428-3.425,7.707-5.137,12.847-5.137c5.141,0,9.423,1.711,12.849,5.137l25.981,25.981c3.617,3.617,5.428,7.902,5.428,12.851
                    c0,4.948-1.811,9.231-5.428,12.847l-53.958,53.959h143.324c4.948,0,9.226,1.809,12.847,5.426c3.613,3.615,5.421,7.898,5.421,12.847
                    V237.539z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default MainNews;
