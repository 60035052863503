import React, { useState, useEffect, useCallback } from 'react';
import { func } from 'prop-types';

// Components
import LikeIcon from './LikeIcon';

const Likes = ({ className, style, news_id, likes, setLike }) => {
  const [isLiked, setIsLiked] = useState(null);

  const initState = useCallback(() => {
    const result = likes && likes.find(({ newsId }) => newsId === news_id);

    if (result) {
      setIsLiked(result.reaction);
    }
  }, [likes, news_id]);

  const handleSetStorage = useCallback(() => {
    if (isLiked !== null) {
      setLike(news_id, isLiked);
    }
  }, [isLiked, news_id, setLike]);

  useEffect(initState, []);
  useEffect(handleSetStorage, [isLiked]);

  return (
    <div className={`noSelect likes ${className}`}>
      <LikeIcon
        setIsLiked={setIsLiked}
        isLiked={isLiked}
        style={style}
      />
      <LikeIcon
        isReversed
        setIsLiked={setIsLiked}
        style={style}
        isLiked={isLiked}
      />
    </div>
  );
};

Likes.propTypes = {
  onLike: func,
  onDislike: func,
};

export default Likes;
