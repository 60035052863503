import React, { memo, useEffect } from 'react';
import { number, func, string, bool } from 'prop-types';

// Types
import { iRef } from '../../../../../../types';

// Utils
import {
  getColorByBgColor,
  hexToRgb,
} from '../../../../../../helpers/colorDetect';
import useIntersection from '../../../../../../hooks/useIntersection';
import { decodeEntities } from '../../../../../../services/decodeData';

// Components
import ThumborImage from '../../../../../common/ThumborImage';
import Likes from '../../../../../common/Likes';

const NewsItem = props => {
  const {
    view_signature,
    view_uuid,
    isDesktopTemplate,
    imageHeight,
    imageWidth,
    isLastItem,
    isBigNews,
    className,
    loaderRef,
    onObserve,
    callback,
    position,
    colours,
    news_id,
    setLike,
    source,
    likes,
    block,
    url,
    img,
  } = props;
  const title = decodeEntities(props.title || '');
  const description = decodeEntities(props.descriptions || '');
  const { observerEntry, observerRef } = useIntersection();
  const newsTrackingData = {
    news_id,
    position,
    block,
    view_signature,
    view_uuid
  };
  const rootClassName = 'detail-news-alternative';

  const cropImageWidth =
    isDesktopTemplate && isBigNews ? imageWidth * 1.4 : imageWidth;
  const cropImageHeight =
    isDesktopTemplate && isBigNews ? imageHeight : imageHeight - 90;

  // Colors
  const edge = isBigNews ? 'left' : 'bottom';
  const { colour1: mainColorHex } = colours && JSON.parse(colours)[edge];
  const mainColor = hexToRgb(mainColorHex);
  const textColor = getColorByBgColor(mainColorHex);

  const start = isDesktopTemplate && isBigNews ? 40 : 55;
  const step = isDesktopTemplate && isBigNews ? 30 : 10;
  const imageGradient = `
    linear-gradient(to ${isDesktopTemplate && isBigNews ? 'right' : 'top'}, 
      rgba(${mainColor}, 1) ${start}%,
      rgba(${mainColor}, 0.85) calc(${start}% + ${step}px),
      rgba(${mainColor}, 0.75) calc(${start}% + ${step * 2}px),
      rgba(${mainColor}, 0.45) calc(${start}% + ${step * 3}px),
      rgba(${mainColor}, 0.30) calc(${start}% + ${step * 4}px),
      rgba(${mainColor}, 0.20) calc(${start}% + ${step * 5}px), 
      rgba(${mainColor}, 0.10) calc(${start}% + ${step * 6}px),
      rgba(${mainColor}, 0.05) calc(${start}% + ${step * 7}px),
      rgba(${mainColor}, 0.00) calc(${start}% + ${step * 8}px),
      rgba(${mainColor}, 0.00) 100%
    )
  `;

  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);

  return (
    <div {...(isLastItem ? { ref: loaderRef } : {})} className={className}>
      <a
        ref={observerRef}
        onMouseDown={() => callback(newsTrackingData, { source })}
        className={rootClassName}
        target="_blank"
        href={url}
        rel="noopener"
        referrerPolicy="origin"
        style={{
          background: `rgb(${mainColor})`,
          height: `${imageHeight}px`,
        }}
      >
        <div className={`${rootClassName}__container`}>
          <ThumborImage
            className={`${rootClassName}__image`}
            height={cropImageHeight}
            width={cropImageWidth}
            alt={title}
            src={img}
          />
          <div
            className={`${rootClassName}__meta`}
            style={{ backgroundImage: imageGradient }}
          >
            <div
              className={`${rootClassName}__title`}
              style={{ color: textColor }}
            >
              {title}
            </div>
            <div
              className={`${rootClassName}__description`}
              style={{ color: textColor }}
            >
              {description}
            </div>
          </div>
        </div>
      </a>
      <div className={`${rootClassName}__footer`}>
        {source && (
          <div
            className={`${rootClassName}__source`}
            style={{ color: textColor }}
          >
            {source}
          </div>
        )}
        <Likes
          style={{ fill: textColor }}
          news_id={news_id}
          likes={likes}
          setLike={setLike}
        />
      </div>
    </div>
  );
};

NewsItem.propTypes = {
  isBigNews: bool,
  isDesktopTemplate: bool,
  imageHeight: number,
  imageWidth: number,
  onObserve: func,
  callback: func,
  news_id: number,
  position: number,
  source: string.isRequired,
  block: string,
  url: string.isRequired,
  img: string,
  className: string,
  loaderRef: iRef,
  isLastItem: bool,
  colours: string,
};

export default memo(NewsItem);
