import React, { useCallback, useEffect, useRef, useState } from 'react';
import { iTemplateProps } from '../../../../types';
import { ZenNews } from './ZenNews';

const Mobile = props => {
  const {
    base,
    domain,
    actions,
    mainClick,
    isObserveNews,
    abcNumber,
    shownItems,
  } = props;
  const mainNewsProps = {
    ...base,
    ...(isObserveNews ? { onObserve: actions.observeNewsHandler } : {}),
    url: base.url,
    onClick: mainClick,
    id: base.news_id,
    source: domain,
    block: 'base',
    position: 1,
    callback: mainClick,
  };
  const containerRef = useRef();
  const [imageSizes, setImageSizes] = useState({
    imageWidth: window.innerWidth - 40,
    imageHeight: window.innerWidth,
  });

  const getSizes = useCallback(() => {
    if (!containerRef.current) return;

    setImageSizes({
      imageWidth: containerRef.current.clientWidth - 40,
      imageHeight: containerRef.current.clientWidth,
    });
  }, [containerRef]);
  
  useEffect(getSizes, [containerRef]);

  return (
    <div className={'app app-mobile app-zen app--abc-' + abcNumber}>
      <div className="container" ref={containerRef}>
        <ZenNews
          allNews={{
            0: { block: 'base', news: [{ ...mainNewsProps }] },
            1: { block: 'rubric', news: shownItems.rubric },
            2: { block: 'last', news: shownItems.last },
            3: { block: 'main', news: shownItems.main },
          }}
          componentProps={imageSizes}
          generalProps={props}
        />
      </div>
    </div>
  );
};

Mobile.propTypes = iTemplateProps;

export default Mobile;
