// Adds '0' before dateNumber if needed, to display it as '01'

const formatDatePart = dateNumber => String(dateNumber).padStart(2, '0');

// Returns two last symbols from year

const formatYear = year => String(year).slice(-2);

export const isTodayDate = date => {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isYesterdayDate = date => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

const getTodayOrYesterdayPrefix = date => {
  if (isTodayDate(date)) {
    return 'Сегодня';
  } else if (isYesterdayDate(date)) {
    return 'Вчера';
  }

  return null;
};

export default function formatDate(utcTimestamp) {
  const utcTimestampInMs = utcTimestamp * 1000;
  const date = new Date(utcTimestampInMs);
  const dayOfMonth = formatDatePart(date.getDate());
  const month = formatDatePart(date.getMonth() + 1);
  const year = formatYear(date.getFullYear());
  const hour = formatDatePart(date.getHours());
  const minutes = formatDatePart(date.getMinutes());
  const datePrefix = getTodayOrYesterdayPrefix(date);

  return `${
    datePrefix ? datePrefix : `${dayOfMonth}.${month}.${year}`
  } ${hour}:${minutes}`;
}
