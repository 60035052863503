import { LAST_SENDED_NEWS_INDEX } from '../constants';

export const getSessionData = ({key, initData}) => {
  let result;
  try {
    result = JSON.parse(sessionStorage.getItem(key)) || initData;
  } catch (error) {
    console.error(error);
  }

  return result;
}

export const setSessionData = ({key, data}) =>
  sessionStorage.setItem(LAST_SENDED_NEWS_INDEX, data);