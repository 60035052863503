import React from 'react';

// Types
import {iTemplateProps } from '../../../../types';

// Utils
import labels from '../../../../labels';

// Components
import InfiniteScroll from '../../../common/InfiniteScroll';
import TitleCategory from '../../../common/TitleCategory';
import TopBlock from '../../../common/TopBlock';
import TileNews from '../../../common/TileNews';
import DetailNews from '../../../common/DetailNews';
import AlternativeDetailNews from '../../../common/DetailNews/AlternativeDetailNews';
import NewsBlock from '../../../common/NewsBlock';

const Desktop = props => {
  const {
    base,
    domain,
    actions,
    mainClick,
    isObserveNews,
    abcNumber,
    activeNavItem,
    hasCommercial,
    loadedItems,
    shownItems,
    isRequesting,
    handleOnAdsClick,
    loadFromState,
    observeNewsHandler,
    isMobile,
    otherRubricLength,
    isShowOtherMaterial,
  } = props;

  const newsListForRubricBlock = activeNavItem
    ? shownItems.main
    : shownItems.rubric.filter(el => !el.isHidden); // abc=62

  const rubricBefore = newsListForRubricBlock.slice(0, otherRubricLength);
  const rubricAfter = newsListForRubricBlock.slice(
    isShowOtherMaterial && !activeNavItem ? otherRubricLength : 0
  );

  return (
    <div className={'app app-desktop app-basic app--abc-' + abcNumber}>
      {!activeNavItem && (
        <TopBlock
          isMobile={isMobile}
          hasCommercial={hasCommercial}
          handleOnAdsClick={handleOnAdsClick}
          mainNewsProps={{
            imageHeight: 250,
            imageWidth: 440,
            img: base.img,
            url: actions.getUrl(base),
            title: base.title,
            source: domain,
            onClick: mainClick,
            position: 1,
            block: 'base',
            onObserve: isObserveNews && observeNewsHandler,
            id: base.news_id,
          }}
          renderNewsList={
            () => <NewsBlock
              newsArray={rubricBefore}
              newsComponent={TileNews}
              base={base}
              mainClick={mainClick}
              actions={actions}
              isObserveNews={isObserveNews}
              componentProps={{
                block: 'rubric',
              }}
            />
          }
        />
      )}
      <div className="container">
        <div
          className="b-column-wrapper grid"
          style={activeNavItem && { marginTop: 16 }}
        >
          {/* label LEFT */}
          <div className="b-left-column">
            <div className="news-container news-container__margin-top">
              <TitleCategory title={labels.more} />
              <InfiniteScroll
                hasMore={
                  activeNavItem
                    ? loadedItems.main.length > shownItems.main.length
                    : loadedItems.rubric.length > shownItems.rubric.length
                }
                isLoading={
                  isRequesting &&
                  (activeNavItem
                    ? !loadedItems.isEmptyLoad.main
                    : !loadedItems.isEmptyLoad.rubric)
                }
                onLoadMore={() =>
                  loadFromState(activeNavItem ? 'main' : 'rubric')
                }
              >
                <NewsBlock
                  newsArray={rubricAfter}
                  newsComponent={DetailNews}
                  base={base}
                  mainClick={mainClick}
                  actions={actions}
                  positionStart={rubricBefore?.length}
                  isObserveNews={isObserveNews}
                  componentProps={{
                    block: activeNavItem ? 'main' : 'rubric',
                  }}
                />
              </InfiniteScroll>
            </div>
          </div>

          {/* label CENTER */}
          <div className="b-center-column">
            <div className="news-container">
              <TitleCategory
                title={activeNavItem ? activeNavItem.title : labels.main}
              />
              <div className="main-tiles-wrapper">
                <InfiniteScroll
                  hasMore={
                    activeNavItem
                      ? loadedItems.rubric.length > shownItems.rubric.length
                      : loadedItems.main.length > shownItems.main.length
                  }
                  isLoading={
                    isRequesting &&
                    (activeNavItem
                      ? !loadedItems.isEmptyLoad.rubric
                      : !loadedItems.isEmptyLoad.main)
                  }
                  onLoadMore={() =>
                    loadFromState(activeNavItem ? 'rubric' : 'main')
                  }
                >
                  <NewsBlock
                    newsArray={activeNavItem ? shownItems.rubric : shownItems.main}
                    newsComponent={TileNews}
                    base={base}
                    mainClick={mainClick}
                    actions={actions}
                    isObserveNews={isObserveNews}
                    componentProps={{ block: activeNavItem ? 'rubric' : 'main' }}
                  />
                </InfiniteScroll>
              </div>
            </div>
          </div>

          {/* label RIGHT */}
          <div className="b-right-column">
            <div className="news-container">
              <TitleCategory title={labels.last} />
              <InfiniteScroll
                hasMore={loadedItems.last.length > shownItems.last.length}
                isLoading={isRequesting && !loadedItems.isEmptyLoad.last}
                onLoadMore={() => loadFromState('last')}
              >
                <NewsBlock
                  newsArray={shownItems.last}
                  newsComponent={DetailNews}
                  base={base}
                  mainClick={mainClick}
                  actions={actions}
                  isObserveNews={isObserveNews}
                  componentProps={{
                    withoutImage: true,
                    withMeta: true,
                    block: 'last',
                  }}
                />
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Desktop.propTypes = iTemplateProps;

export default Desktop;
