export default ({ originalUrlParams, newsItem, user, loadedItems }) => {
  const { informer_id, site_id, a_b_c } = originalUrlParams;
  const apiUrl = `${process.env.REACT_APP_URL}/redirect?top_payload=`;
  
  const urlParams = {
    news_id: newsItem.news_id,
    url: newsItem.url,
    click_signature: newsItem.click_signature,
    click_uuid: newsItem.click_uuid,
    user,
    informer_id,
    site_id,
    a_b_c,
    timestamp: Date.now(),
  };

  // Clean undefined params
  Object.keys(urlParams).map(key =>
    !urlParams[key] ? delete urlParams[key] : null,
  );

  return (
    apiUrl +
    window.btoa(unescape(encodeURIComponent(JSON.stringify(urlParams))))
  );
};

