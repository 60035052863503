import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { decodeEntities } from '../../../services/decodeData';
import ThumborImage from '../ThumborImage';
import useIntersection from '../../../hooks/useIntersection';

import './style.scss';

const rootClassName = 'detail-news-alternative';

const AlternativeDetailNews = props => {
  const {
    view_signature,
    view_uuid,
    imageHeight,
    imageWidth,
    onObserve,
    callback,
    news_id,
    position,
    source,
    block,
    url,
    img,
  } = props;
  const title = decodeEntities(props.title);
  // const sourceName = source && source.split('//')[1];
  const { observerEntry, observerRef } = useIntersection();
  const newsTrackingData = {
    news_id,
    position,
    block,
    view_signature,
    view_uuid,
  };
  
  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);

  return (
    <a
      ref={observerRef}
      onMouseDown={() => callback(newsTrackingData)}
      className={rootClassName}
      target="_blank"
      href={url}
      rel="noopener"
      referrerPolicy="origin"
    >
      <div className={`${rootClassName}__container`}>
        <ThumborImage
          className={`${rootClassName}__image`}
          height={imageHeight}
          width={imageWidth}
          alt={title}
          src={img}
        />
        <div className={`${rootClassName}__meta`}>
          {source && (
            <div className={`${rootClassName}__source`}>
              <svg
                viewBox="0 0 438.536 438.536"
                height="438.536px"
                width="438.536px"
              >
                <path
                  d="M414.41,24.123C398.333,8.042,378.963,0,356.315,0H82.228C59.58,0,40.21,8.042,24.126,24.123
                        C8.045,40.207,0.003,59.576,0.003,82.225v274.084c0,22.647,8.042,42.018,24.123,58.102c16.084,16.084,35.454,24.126,58.102,24.126
                        h274.084c22.648,0,42.018-8.042,58.095-24.126c16.084-16.084,24.126-35.454,24.126-58.102V82.225
                        C438.532,59.576,430.49,40.204,414.41,24.123z M365.449,228.402c0,7.994-3.717,13.606-11.136,16.844
                        c-2.471,0.951-4.859,1.427-7.139,1.427c-5.134,0-9.418-1.811-12.847-5.424l-41.11-41.112L140.764,352.599
                        c-3.621,3.614-7.9,5.425-12.85,5.425c-4.952,0-9.235-1.811-12.851-5.425l-29.121-29.126c-3.617-3.61-5.426-7.901-5.426-12.847
                        c0-4.944,1.809-9.229,5.426-12.843l152.462-152.464l-41.113-41.112c-5.902-5.52-7.233-12.178-3.999-19.985
                        c3.234-7.421,8.852-11.136,16.846-11.136h137.037c4.948,0,9.232,1.81,12.854,5.428c3.613,3.614,5.421,7.898,5.421,12.847V228.402z"
                />
              </svg>
              {source}
            </div>
          )}
          <div className={`${rootClassName}__title`}>{title}</div>
        </div>
      </div>
    </a>
  );
};

AlternativeDetailNews.propTypes = {
  position: PropTypes.number,
  callback: PropTypes.func,
  baseUrl: PropTypes.string,
  source: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default memo(AlternativeDetailNews);
