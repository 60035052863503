import React, { useRef, useEffect, useCallback } from 'react';
import Loader from '../Loader';
import Button from '../Button';

const InfiniteScroll = props => {
  const {
    loaderComponent,
    buttonProps,
    onLoadMore,
    useButton,
    isLoading,
    children,
    hasMore,
  } = props;
  const loaderRef = useRef();

  const intersectionHandler = useCallback(
    ([target]) => {
      if (
        (target.isIntersecting && hasMore) ||
        (!target.isIntersecting && target.boundingClientRect.y < 0)
      ) {
        onLoadMore();
      }
    },
    [hasMore, onLoadMore],
  );

  useEffect(() => {
    if (useButton) return;

    const loader = loaderRef.current;
    const options = {
      threshold: 0.25,
      rootMargin: `${window.innerHeight * 2}px`,
    };

    const observer = new IntersectionObserver(intersectionHandler, options);

    if (loader) {
      observer.observe(loader);
    }

    if (!hasMore) {
      observer.unobserve(loader);
    }

    return () => observer.unobserve(loader);
  }, [loaderRef, intersectionHandler, hasMore, useButton]);

  return (
    <>
      {children}
      {!useButton && <div ref={loaderRef} />}
      {isLoading && (loaderComponent || <Loader inline logo />)}
      {useButton && hasMore && (
        <Button
          title="Показать еще"
          style={{ marginTop: 15 }}
          callback={onLoadMore}
          {...buttonProps}
        />
      )}
    </>
  );
};

export default InfiniteScroll;
