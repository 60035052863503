import { useState, useEffect, useRef } from 'react';

// The hooks watches when element is intersected. Calls the setstate and unsubscribes from listener

const useIntersection = options => {
  const [observerEntry, setEntry] = useState({});
  const observerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEntry(entry);
          observer.unobserve(observerRef.current);
        }
      },
      {
        threshold: 1,
        ...options,
      },
    );
    observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [observerRef, options]);

  return { observerEntry, observerRef };
};

export default useIntersection;
