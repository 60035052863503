import React from 'react';
import Nav from '../../Nav';

const MobileMenu = ({ className, onClose, ...otherProps }) => (
  <div className={className}>
    <div className="container">
      <Nav {...otherProps} />
    </div>
    <div className='header__hide-area' onClick={onClose}/>
  </div>
);

export default MobileMenu;
