import React, { useEffect, useMemo, useState } from 'react';
import ReactMetaTags, { ReactTitle } from 'react-meta-tags';
import {string} from 'prop-types';
import { extractByKeys } from '../AppRoot/helper';
import HeadStyles from './components/HeadStyles';
import { iAppData } from '../../../types';

const Head = ({ data, themeColor, googleFont }) => {
  const currentBase = useMemo(
    () => extractByKeys(data, 'content', 'base')[0]
    , [data]
  );
  const [base, setBase] = useState(currentBase);

  useEffect(() => {
    if (currentBase) setBase(currentBase);
  }, [currentBase]);

  // prettier-ignore
  return (
    <>
      {base && (
        <>
          <ReactTitle title={`MY.TOP - ${base.title}`} />
          <ReactMetaTags>
            <meta name="description" content={`MY.TOP - ${base.description}`}/>
            <meta name="keywords" content="MY.TOP" />
            <meta property="og:url" content={window.location.origin} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`"MY.TOP - ${base.title}`} />
            <meta property="og:description" content={`MY.TOP - ${base.description}`}/>
            <meta property="og:image" content={base.img} />
            <meta property="og:image:width" content="450" />
            <meta property="og:image:height" content="250" />
          </ReactMetaTags>
        </>
      )}
      <ReactMetaTags>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="theme-color" content={themeColor} />
        <HeadStyles googleFont={googleFont} />
      </ReactMetaTags>
    </>
  );
};

Head.propTypes = {
  data: iAppData.isRequired,
  themeColor: string,
  googleFont: string,
};

export default Head;
