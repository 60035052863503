import {
  string,
  number,
  arrayOf,
  exact,
  func,
  shape,
  oneOfType,
  oneOf,
  bool,
  instanceOf,
  node,
  element,
  elementType,
} from 'prop-types';

export const iRef = oneOfType([func, shape({ current: instanceOf(Element) })]);

const basicNewsItemProps = {
  url: string,
  img: string,
  title: string,
  source: string,
  colours: string,
  description: string,
  click_signature: string,
  click_uuid: string,
  view_signature: string,
  view_uuid: string,
  isHidden: bool,
}

export const iNewsItem = {
  ...basicNewsItemProps,
  created: number,
  weight: number,
  news_id: number,
};
export const iBaseItem = {
  ...basicNewsItemProps,
  news_id: number,
  category_id: number,
  cluster: string,
  language_id: number,
};

export const iShownItems = {
  last: arrayOf(exact(iNewsItem)),
  main: arrayOf(exact(iNewsItem)),
  rubric: arrayOf(oneOfType([shape(iBaseItem), shape(iNewsItem)])),
};

export const iActions = {
  getUrl: func,
  load: func,
  newsClickHandler: func,
  observeNewsHandler: func,
};

export const iTemplateProps = {
  abcNumber: string,
  actions: exact(iActions),
  activeNavItem: oneOfType([shape({}), oneOf([null])]),
  base: oneOfType([exact(iBaseItem), exact(iNewsItem)]),
  component: oneOfType([element, elementType, node]),
  currentDate: instanceOf(Date),
  data: arrayOf(shape({})),
  domain: string,
  enableOrderParamForBlocks: arrayOf(string),
  handleOnAdsClick: func,
  hasCommercial: bool,
  hotNewsRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  iframeMessage: func,
  isObserveNews: bool,
  isRequesting: bool,
  isMobile: bool,
  isShowOtherMaterial: bool,
  isRubricInfinite: bool,
  isActiveNavItem: bool,
  isFirstLoad: bool,
  isFirstRequest: bool,
  isMultipleSending: bool,
  isNewSession: bool,
  isTimerDisabled: bool,
  lastNewsBlockRef: iRef,
  loadedItems: shape({
    ...iShownItems,
    addQty: shape({ last: number, main: number, rubric: number }),
    ids: shape({
      last: arrayOf(number),
      main: arrayOf(number),
      rubric: arrayOf(number),
    }),
    initQty: shape({ last: number, main: number, rubric: number }),
    isEmptyLoad: shape({ last: bool, main: bool, rubric: bool }),
  }),
  loadFromState: func,
  mainClick: func,
  newsIdsFromIframe: arrayOf(number),
  newsItemsCount: shape({
    initQty: shape({ last: number, main: number, rubric: number }),
  }),
  observeNewsHandler: func,
  originalUrlParams: shape({ is_counted: bool }),
  otherRubricLength: number,
  params: shape({
    params: shape({}),
    user: string,
    actions: string,
    type: string,
  }),
  renderDetailNews: func,
  shownItems: exact(iShownItems),
  shouldSendViewedNewsFromIframe: bool,
  timer: string,
  url: string,
  urlParams: shape({}),
  // hiddenNewsBlocks: ,
  // useImageForLastNews,
  // enableLangParam: undefined,
};

export const iCorrection = {
  base: number,
  rubric: number,
  last: number,
  main: number,
};

export const iAppData = arrayOf(
  exact({
    content: exact({
      base: exact(iBaseItem),
      ...iShownItems,
    }),
    message: string,
    user: string,
  }),
);
