import React from 'react';
import classNames from 'classnames';
import './style.scss';

const modify = props =>
  classNames({
    'button--filled': props.filled,
    'button--success': props.success,
  });

export default function Button(props) {
  const { title, callback, filled, success, ...otherProps } = props;

  return (
    <span
      className={`button ${modify(props)}`}
      {...otherProps}
      onClick={callback}
    >
      {title}
    </span>
  );
}
