import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const GoogleAdvertisement = props => {
  const { style, clientId, slotId, adProps, handleOnAdsClick } = props;
  const googleAdsRef = useRef();

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  useEffect(() => {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        const iframe = googleAdsRef.current.querySelector('iframe[id*="aswift_"]');

        if (!iframe) return;

        const listener = window.addEventListener('blur', () => {
          if (document.activeElement === iframe) {
            handleOnAdsClick();
          }
          window.removeEventListener('blur', listener);
        });
      }
    }
  }, [handleOnAdsClick, googleAdsRef]);

  return (
    <ins
      ref={googleAdsRef}
      className="adsbygoogle"
      style={{ display: 'block', ...style }}
      data-ad-client={clientId}
      data-ad-slot={slotId}
      data-ad-format="auto"
      data-full-width-responsive="true"
      {...adProps}
    ></ins>
  );
};

GoogleAdvertisement.propTypes = {
  clientId: PropTypes.string.isRequired,
  adProps: PropTypes.shape({}),
  slotId: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default GoogleAdvertisement;
