import React from 'react';
import PropTypes from 'prop-types';
import MainNews from '../MainNews';
import './style.scss';
import classNames from 'classnames';
import GoogleAdvertisement from '../GoogleAdvertisement';

const rootClassName = 'top-block';
const modifiedClass = props =>
  classNames({
    [`${rootClassName}`]: true,
    [`${rootClassName}--ga`]: props.hasCommercial,
  });

const TopBlock = props => {
  const { renderNewsList, mainNewsProps, hasCommercial, handleOnAdsClick } = props;

  return (
    <div className="container">
      <div className={modifiedClass(props)}>
        <MainNews {...mainNewsProps} />
        <div className="news">{renderNewsList()}</div>
        {hasCommercial &&
          <div className='ga-wrapper'>
            <GoogleAdvertisement
              clientId='ca-pub-1745069445907180'
              slotId='9693921721'
              style={{width: '200px', height: '400px'}}
              handleOnAdsClick={handleOnAdsClick}
              adProps={{
                'data-ad-format': null
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

TopBlock.propTypes = {
  modifyTopBlock: PropTypes.bool,
  isMobile: PropTypes.bool,
  withAd: PropTypes.bool,
  mainNewsProps: PropTypes.shape({
    imageHeight: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    source: PropTypes.string,
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default TopBlock;
