import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { decodeEntities } from '../../../services/decodeData';
import formatDate from '../../../services/formatDate';
import ThumborImage from '../ThumborImage';
import useIntersection from '../../../hooks/useIntersection';
import './style.scss';

const modifyDetailNews = ({ imageAlignment, largeFontSize }) =>
  classNames({
    [`detail-wrapper--${imageAlignment}`]: imageAlignment,
    'detail-wrapper--large': largeFontSize,
  });

const modifyDetailLink = props =>
  classNames({
    'detail-link--with-meta': props.withMeta,
    mix: props.mix,
    hot: props.isHotNews,
  });

const modifySource = () =>
  classNames({
    'detail-source--left': true,
    'detail-source--with-margin': true,
  });

const modifyImage = ({ imageSize }) =>
  classNames({
    [`img-wrapper--${imageSize}`]: imageSize,
  });

const DetailNews = props => {
  const {
    view_signature,
    view_uuid,
    onObserve,
    withoutImage,
    imageHeight,
    imageWidth,
    withSource,
    className,
    callback,
    withMeta,
    created,
    news_id,
    position,
    block,
    source,
    style,
    img,
    url,
  } = props;
  const { observerEntry, observerRef } = useIntersection();
  const newsTrackingData = {
    news_id,
    position,
    block,
    view_signature,
    view_uuid,
  };
  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);
  const title = decodeEntities(props.title);

  return (
    <a
      ref={observerRef}
      style={style}
      href={url}
      target="_blank"
      rel="noopener"
      referrerPolicy="origin"
      onMouseDown={() => callback(newsTrackingData)}
      className={`detail-link ${className} ${modifyDetailLink(props)}`}
    >
      {withMeta && (
        <div className="detail-meta">
          <div className="detail-source">
            {source && (
              <>
                <div className="detail-source-image">
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${source}`}
                      alt={source}
                    />
                </div>
                <span>{source}</span>
              </>
            )}
          </div>
          <div className="detail-date">{formatDate(created)}</div>
        </div>
      )}
      <div className={`detail-wrapper ${modifyDetailNews(props)}`}>
        {!withoutImage && (
          <span className={`img-wrapper ${modifyImage(props)}`}>
            <ThumborImage
              width={imageWidth}
              height={imageHeight}
              src={img}
              alt={title}
              className="detail-img"
            />
          </span>
        )}
        <div className="detail-content-wrapper">
          <span className="detail-title">{title}</span>
          {withSource && source && (
            <div className={`detail-source ${modifySource()}`}>
              <div className="detail-source-image">
                <img
                  src={`https://www.google.com/s2/favicons?domain=${source}`}
                  alt={source}
                />
              </div>
              <span>{source}</span>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

DetailNews.defaultProps = {
  imageAlignment: 'left',
  imageSize: 'small',
  imageWidth: 80,
  imageHeight: 80,
};

DetailNews.propTypes = {
  imageAlignment: PropTypes.oneOf(['left', 'right']),
  withoutImage: PropTypes.bool,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  imageSize: PropTypes.oneOf(['small', 'medium']),
  className: PropTypes.string,
  withMeta: PropTypes.bool,
  callback: PropTypes.func,
  baseUrl: PropTypes.string,
  created: PropTypes.number,
  source: PropTypes.string,
  img: PropTypes.string,
  mix: PropTypes.bool,
  url: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

export default memo(DetailNews);
