import { LOCAL_STORAGE_PARAMS } from '../constants';

export const checkFirstTimeLoading = urlParams => {
  const urlParamsJson = JSON.stringify(urlParams);
  const urlParamsLocal = localStorage.getItem(LOCAL_STORAGE_PARAMS);
  const isSameParams = urlParamsLocal === urlParamsJson;

  if (!isSameParams) localStorage.setItem(LOCAL_STORAGE_PARAMS, urlParamsJson);

  return isSameParams;
};

export const setLocalStorage = (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));
export const getLocalStorage = (key, type = {}) => {
  let storage = type;

  try {
    storage = JSON.parse(localStorage.getItem(key));
  } catch (e) { }

  return storage;
}
