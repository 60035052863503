import React from 'react';
import InfiniteScroll from '../../common/InfiniteScroll';

export default ({
  isWithScroll,
  children,
  scrollComponent: ScrollComponent = InfiniteScroll,
  ...scrollProps
}) =>
  isWithScroll ? (
    <ScrollComponent {...scrollProps}>{children}</ScrollComponent>
  ) : (
    children
  );
